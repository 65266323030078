export default class QuestionnarieService {

    getQuestion(link) {
        return fetch('/backend/questionnaire/infoquestionnaire/'+link)
            .then((res) => {
                if(res.ok) {
                    var x;
                    try {
                        x = res.json();
                    } catch (err) {
                        x = { "code": "404", "error": err.message};
                    }
                    return x;
                    
                }
                else throw new Error("Status code error :" + res.status)
            })
            .then(data => data)
            .catch(
                err=>{
                    console.log("ERR", err)
                    return { "code":"404"}
                }
            );
    }
/*
    getQuestion(link) {
        return fetch('/backend/questionnaire/infoquestionnaire/'+link)
            .then(res => res.json())
            .then(d => d);
    }
*/

    async addQuestion (question) {
        const requestOptions = {
            method: "POST",
            // headers: authHeader(),
            body: JSON.stringify(question)
        };
        // console.log ("ADD", question)
        const response = await fetch("/backend/questionnaire/addquestionnaire/"+question.link, requestOptions);
        const data = await response.json();
        return  (data);
    }

    getQuestionView(link) {

        return fetch('/backend/questionnaire/viewquestionnaire/'+link)
            .then((res) => {
                if(res.ok) {
                    var x;
                    try {
                        x = res.json();
                    } catch (err) {
                        x = { "code": "404", "error": err.message};
                    }
                    return x;
                    
                }
                else throw new Error("Status code error :" + res.status)
            })
            .then(data=>data)
            .catch(
                err=>{
                    console.log("ERR", err)
                    return { "code":"404"}
                }
            );

/*        return fetch('/backend/questionnaire/viewquestionnaire/'+link).then(res => res.json())
            .then(d => d);
            */
    }

}
